
  .loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 34, 0.6); /* Color de fondo semi-transparente */
    z-index: 9999;
  }
.loader {
    width: fit-content;
    font-weight: bold;
    font-family: sans-serif;
    text-align: center;
    max-width: 200px;
    font-size: 30px;
    padding: 0 5px 8px 0;
    color:rgb(252, 194, 135);
    background: repeating-linear-gradient(90deg,currentColor 0 8%,#0000 0 10%) 200% 100%/200% 3px no-repeat;
    animation: l3 2s steps(6) infinite;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
  .loader:before {
    content:""
  }
  @keyframes l3 {to{background-position: 80% 100%}}