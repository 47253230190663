/* Global Styles */
body {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 100%;
    background-color: #fff;
   
    height: 100vh; /* Ajustamos el alto al 100% de la ventana */
}

/* Headings */
h1,
h2 {
    margin-top: 0;
}

h1 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
}

h2 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 400;
}

/* Card Container */
.screen {
    max-width: 100%;
    height: 90vh;
    background-image: url('../img/tiopa-background.png'); /* Ruta de la imagen de fondo */
    background-size: cover; /* Hace que la imagen cubra toda la pantalla */
    background-position: center; /* Centra la imagen en la pantalla */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
    background-color: rgba(255, 255, 255, 0.5);
    padding: 20px; /* Agrega un espacio interno */
   
}

.card-container {
    max-width: 720px;
    margin: 1vh auto; 
    height: 60vh; /* Ajustamos la altura al 100% */
    display: flex;
    align-items: center;
    align-content: center;
}

/* Card Body */
.card-body {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all .7s linear;
    display: flex;
    align-items: center;
    align-content: center;
    
}

img {
    max-width: 100%;
    max-height: 100%;
}

.card-container.touch .card-body {
    transform: rotateY(180deg);
}

.card-container:hover .card-body{
    transform: rotateY(180deg);
}

.card-container:hover > .card-body > .side-front,
.card-container.touch > .card-body > .side-front {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-in, visibility .75s linear;
}

/* Card Sides */
.card-side {
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    color: #212121;
    
    background-color: #fff;
    backface-visibility: hidden;
    box-shadow: 0 10px 35px rgba(50, 50, 93, .1), 0 2px 15px rgba(0, 0, 0, .07);
}

/* Front Side */
.side-front [class^=col-xs]:first-of-type {
    padding-left: 0;
}

.side-front-content {
    padding-top: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
}

/* Back Side */
.side-back {
    z-index: 1;
    justify-content:center;
    text-align: center;
    transform: rotateY(180deg);
}
container-fluid
{

    justify-content:center;
    text-align: center;
    display: flex;
   
}
/* Form */
.card-form {
    display: flex;
    flex-direction: column; /* Orienta los elementos en columna */
    justify-content: center; /* Centra verticalmente los elementos */
    align-items: center; /* Centra horizontalmente los elementos */
    margin-top: 32px;
    padding: 0 16px; /* Añadimos padding horizontal para los formularios */
}
.text-container {
    text-align: justify;
}

.card-form .row + .row,
.card-form .row + fieldset,
.card-form fieldset + fieldset {
    margin-top: 16px;
}

/* Input and Textarea Styles */
input,
textarea {
    padding: 8px;
    width: calc(100% - 16px); /* Restamos 16px para dejar espacio en los bordes */
    border: 1px solid #eee; /* Simplificamos la declaración del borde */
    border-radius: 4px; /* Agregamos bordes redondeados */
    box-sizing: border-box; /* Evita que el padding afecte el ancho total */
    margin-bottom: 16px; /* Agregamos un espacio inferior para separar los elementos */
}

textarea {
    max-height: 80px;
    resize: vertical;
}

/* Button Styles */
.btn-primary {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #0c81f6;
    border: none;
    border-radius: 4px; /* Agregamos bordes redondeados */
    box-shadow: 0 10px 35px rgba(50, 50, 93, .1), 0 2px 15px rgba(0, 0, 0, .07);
    transition: background-color .25s ease-in, box-shadow .25s ease-in;
    width: 100%; /* Hacemos que el botón ocupe todo el ancho del contenedor */

    &:focus,
    &:hover {
        background-color: lighten(#0c81f6, 15%);
        box-shadow: 0 18px 35px rgba(50, 50, 93, .1), 0 8px 15px rgba(0, 0, 0, .07);
    }
}

/* Social Links */
.social-links {
    padding: 0;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    list-style-type: none;

    li:not(:first-of-type) { /* Usamos :not() para seleccionar todos menos el primer elemento */
        margin-left: 24px;
    }

    a {
        color: #212121;
        transition: opacity .25s ease-in;

        &:focus,
        &:hover {
            opacity: .5;
        }
    }

    i {
        font-size: 24px;
    }
}
.cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Hace que la imagen cubra toda el área manteniendo la relación de aspecto */
}

background-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Ajusta el nivel de opacidad según sea necesario */
    width: 100%;
    height: 100%;
  }
  
  .card-side.side-front {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Alinea el contenido en la parte inferior */
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
  }

  .side-front-content {
    padding: 20px;
  }
  
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el contenido horizontalmente */
  }
  
/* Responsive Styles */
@media (max-width: 767px) {
    .screen {
        background-image: url('../img/tiopa-background-phone.png'); /* Ruta de la imagen de fondo */ 
    }
    .card-container {
        margin-top: 0;
        max-width: 100%;
        height: 88vh;
        margin: 0 auto; /* Cambiamos esto para centrar horizontalmente */
        
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card-body {
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transition: all .7s linear;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .side-front{
        display: none; /* Oculta la parte frontal de la tarjeta en dispositivos móviles */
    }
    .side-back {
        padding: 16px;
    }

    /* Ajustes adicionales para componentes en dispositivos pequeños */
    input,
    textarea {
        margin-bottom: 12px; /* Reducimos el espacio inferior en dispositivos pequeños */
    }

    .btn-primary {
        padding: 8px 16px;
        font-size: 16px;
    }
}

/* Global Styles */
body {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 100%;
    background-color: #fff;
}

/* Headings */
h1,
h2 {
    margin-top: 0;
}

h1 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
}

h2 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 400;
}

/* Card Container */
.card-container {
    position: relative;
    z-index: 1;
    margin: 32px auto;
    max-width: 720px;
    height: 450px;
    perspective: 1000px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

/* Card Body */
.card-body {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: all .7s linear;
}

/* Flipping the Card */
.card-container:hover .card-body {
    transform: rotateY(180deg);
}

.card-container:hover > .card-body > .side-front {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-in, visibility .75s linear;
}

/* Card Sides */
.card-side {
    position: absolute;
    top: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    color: #212121;
    background-color: #fff;
    backface-visibility: hidden;
    box-shadow: 0 10px 35px rgba(50, 50, 93, .1), 0 2px 15px rgba(0, 0, 0, .07);
}

/* Front Side */
.side-front [class^=col-xs]:first-of-type {
    padding-left: 0;
}

.side-front-content {
    padding-top: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
}

/* Back Side */
.side-back {
    z-index: 2;
    padding: 32px;
    text-align: center;
    transform: rotateY(180deg);
}

/* Form */
.card-form {
    margin-top: 32px;
}

.card-form .row + .row,
.card-form .row + fieldset,
.card-form fieldset + fieldset {
    margin-top: 16px;
}

/* Input and Textarea Styles */
input,
textarea {
    padding: 8px;
    width: 100%;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #eee;
    border-left: 0;

    &:focus {
        outline: 0;
        border-bottom: 1px solid #0c81f6;
    }
}

textarea {
    max-height: 80px;
    resize: vertical;
}

/* Button Styles */
.btn-primary {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #0c81f6;
    border: none;
    box-shadow: 0 10px 35px rgba(50, 50, 93, .1), 0 2px 15px rgba(0, 0, 0, .07);
    transition: background-color .25s ease-in, box-shadow .25s ease-in;

    &:focus,
    &:hover {
        background-color: lighten(#0c81f6, 15%);
        box-shadow: 0 18px 35px rgba(50, 50, 93, .1), 0 8px 15px rgba(0, 0, 0, .07);
    }
}

/* Social Links */
.social-links {
    padding: 0;
    margin-top: 32px;
    display: flex;
    justify-content: center;
    list-style-type: none;

    li:nth-of-type(n+2) {
        margin-left: 24px;
    }

    a {
        color: #212121;
        transition: opacity .25s ease-in;

        &:focus,
        &:hover {
            opacity: .5;
        }
    }

    i {
        font-size: 24px;
    }
}

/* Responsive Styles */
@media (max-width: 767px) {
    .card-container {
        max-width: 100%;
        height: 730px;
    }

    .card-body {
        height: 730px;
    }

    .side-front,
    .side-back {
        padding: 16px;
    }

    .btn-primary {
        width: 100%;
    }
}

